import * as Pikaday from "pikaday";
import * as moment from "moment";
import localization from 'moment/locale/tr';
import './component.scss';

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect () {
    moment.updateLocale('tr', localization);

    let field = this.inputTarget;

    let picker = new Pikaday({
      field: field,
      firstday: 1,
      format: 'YYYY-MM-DD',
      i18n: {
        previousMonth : 'Önceki Ay',
        nextMonth     : 'Sonraki Ay',
        months        : ['Ocak','Şubat','Mart','Nisan','Mayıs','Haziran','Temmuz','Ağustos','Eylül','Ekim','Kasım','Aralık'],
        weekdays      : ['Pazar','Pazartesi','Salı','Çarşamba','Perşembe','Cuma','Cumartesi'],
        weekdaysShort : ['Paz','Pzt','Sal','Çrş','Prş','Cum','Cts']
    }
    });
  }
}
