import './component';

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['header', 'content', 'openIndicator', 'closeIndicator']
  opened = false

  connect() {
    if (this.openContent === 'true') {
      this.open()
      this.showContent()
    }
  }

  toggle() {
    if (this.headerTarget.classList.contains('open')) {
      this.close()
    } else {
      this.open()
    }

    this.showContent()
  }

  open() {
    this.headerTarget.classList.add('open')
    this.headerTarget.classList.remove('close')
    this.openIndicatorTarget.classList.add('hidden')
    this.closeIndicatorTarget.classList.remove('hidden')
    this.opened = true
  }

  close() {
    this.headerTarget.classList.remove('open')
    this.headerTarget.classList.add('close')
    this.openIndicatorTarget.classList.remove('hidden')
    this.closeIndicatorTarget.classList.add('hidden')
    this.opened = false
  }

  showContent() {
    if (this.opened) {
      this.contentTarget.classList.remove('hidden')
      this.contentTarget.classList.add('block')
    } else {
      this.contentTarget.classList.add('hidden')
      this.contentTarget.classList.remove('block')
    }
  }

  get openContent() {
    return this.data.get('openContent')
  }
}
