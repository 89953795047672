import './component.scss'

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['value', 'selector']

  connect() {
    if(this.valueTarget.value !== '') {
      this.addActiveClass(this.selectorTargets.filter(s => s.dataset.value === this.valueTarget.value)[0])
    }
  }

  setValue(event) {
    const valueId = event.currentTarget.dataset.value;
    this.valueTarget.value = valueId;
    this.removeActiveClass();
    this.addActiveClass(event.currentTarget);
  }

  removeActiveClass() {
    this.selectorTargets.map(v => v.classList.remove('active'))
  }

  addActiveClass(target) {
    target.classList.add('active')
  }

  get active() {
    return this.data.get('active') === 'true';
  }
}
