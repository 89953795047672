import './component.scss';

import { Turbo, cable } from '@hotwired/turbo-rails';
import { Controller } from 'stimulus'

export default class extends Controller {
  eeProductClick (event) {
    if( window.google_tag_manager !== undefined ) {
      event.preventDefault()

      let self = this

      window.localStorage.setItem('list', this.data.get('list'))

      dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
          'click': {
            'actionField': { 'list': this.data.get('list') },
            'products': [{
              'name': this.data.get('name'),
              'id': this.data.get('id'),
              'price': this.data.get('price'),
              'category': this.data.get('category'),
              'position': this.data.get('index')
             }]
           }
         },
         'eventCallback': function() {
           Turbo.visit(self.data.get('url'))
         }
       });
    }
  }

  eeAddToCart (event) {
    if( window.google_tag_manager !== undefined ) {
      event.preventDefault()

      let self = this
      var list = null

      if(localStorage.hasOwnProperty('list')) {
        list = localStorage.getItem('list')
      }

      dataLayer.push({
        'event': 'addToCart',
        'ecommerce': {
          'currencyCode': 'TRY',
          'add': {
            'actionField': { 'list': list },
            'products': [{
              'name': this.data.get('name'),
              'id': this.data.get('id'),
              'price': this.data.get('price'),
              'category': this.data.get('category')
             }]
           }
         },
         'eventCallback': function() {
           Turbo.visit(self.data.get('url'))
         }
       });
    }
  }
}
