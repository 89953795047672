import { tns } from 'tiny-slider/src/tiny-slider'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'container', 'thumbnails' ]

  connect () {
    this.slider = tns({
      container: this.containerTarget,
      items: this.items,
      autoplay: this.autoplay,
      mode: this.mode,
      responsive: this.responsive,
      mouseDrag: true,
      arrowKeys: true,
      controls: false,
      nav: false,
      autoplayButtonOutput: false
    })

    let self = this

    if (this.hasThumbnailsTarget) {
      this.slider.events.on('indexChanged', function (info, eventName) {
        for (var i = 0; i < self.thumbnailsTarget.childElementCount; i++) {
          self.thumbnailsTarget.children[i].classList.remove('active')
        }

        if (info.index == self.thumbnailsTarget.childElementCount) {
          self.thumbnailsTarget.children[0].classList.add('active')
        } else {
          if (typeof self.thumbnailsTarget.children[info.index] !== 'undefined') {
            self.thumbnailsTarget.children[info.index].classList.add('active')
          }
        }
      })
    }
  }

  next() {
    this.slider.goTo('next')
  }

  prev() {
    this.slider.goTo('prev')
  }

  goTo(event) {
    this.slider.goTo(event.currentTarget.dataset.index)
  }

  get items() {
    return this.data.get('items')
  }

  get autoplay() {
    return this.data.get('autoplay')
  }

  get mode() {
    return this.data.get('mode')
  }

  get responsive() {
    return JSON.parse(this.data.get('responsive'))
  }
}
