import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'content', 'toggleMoreButton' ]
  contentIsVisible = true

  connect () {
    this.addMoreButton()

    if (this.showMore === 'true') {
      this.showContent()
    } else {
      this.hideContent()
    }
  }

  showContent() {
    this.contentTarget.classList.remove(this.contentHeightClass)
    this.contentTarget.classList.remove('overflow-hidden')
    this.contentIsVisible = true
    this.toggleMoreButtonTarget.text = this.showLessText
  }

  hideContent() {
    this.contentTarget.classList.add(this.contentHeightClass)
    this.contentTarget.classList.add('overflow-hidden')
    this.contentIsVisible = false
    this.toggleMoreButtonTarget.text = this.showMoreText
  }

  toggleMore() {
    if (this.contentIsVisible) {
      this.hideContent()
    } else {
      this.showContent()
    }
  }

  addMoreButton() {
    var button = document.createElement('a')
    button.classList.add('cursor-pointer')
    button.classList.add('text-blue-600')
    button.classList.add('hover:text-blue-500')
    button.classList.add('focus:text-blue-700')
    button.setAttribute('data-action', 'click->truncate#toggleMore')
    button.setAttribute('data-target', 'truncate.toggleMoreButton')

    this.element.append(button)
  }

  get showMoreText () {
    return this.data.get('showMoreText')
  }

  get showLessText () {
    return this.data.get('showLessText')
  }

  get showMore () {
    return this.data.get('showMore')
  }

  get contentHeightClass() {
    return this.data.get('contentHeightClass')
  }
}
