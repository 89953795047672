import './component.scss'

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['value', 'selector'];

  setValue(event) {
    const valueId = event.currentTarget.dataset.value;
    this.valueTarget.value = valueId;
  }
}
