import { Turbo, cable } from '@hotwired/turbo-rails';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import "channels";
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import "../stylesheets/marketplace.scss";
import SliderController from '../controllers/slider_controller';
import TruncateController from '../controllers/truncate_controller';
import SearchController from '../controllers/search_controller';
import 'lazysizes';

Rails.start();
ActiveStorage.start();
const application = Application.start();
const context = require.context('../../components', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

application.register('slider', SliderController);
application.register('truncate', TruncateController);
application.register('search', SearchController);

// Lazysizes: Fotoğrafların yüklenme yöntemini ayarlar
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}
