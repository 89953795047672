import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['query']

  searchStarted = false

  connect() {
    let urlParams = new URLSearchParams(window.location.search)
    let query = urlParams.get('q')

    if(query !== null && query !== '') {
      this.queryTarget.value = query
      this.showSearchInput()
    }
  }

  startSearching(event) {
    event.preventDefault()

    if(this.searchStarted === true) {
      this.search()
    } else {
      this.showSearchInput()
    }
  }

  endSearching(event) {
    event.preventDefault()

    if(event.target.value === '') {
      this.hideSearchInput()
    }
  }

  search() {
    this.element.children[0].submit()
  }

  showSearchInput() {
    this.searchStarted = true
    this.queryTarget.classList.remove('hidden')
    this.queryTarget.focus()
  }

  hideSearchInput() {
    this.searchStarted = false
    this.queryTarget.classList.add('hidden')
  }
}
